import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/index'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import lojas from '../utils/lojas-doacoes.json'
import './styles/lojas-doacoes.scss'

// const Banner = ({ bannerLiquidacao, bannerLiquidacaoMobile }) => {
//   const sources = withArtDirection(getImage(bannerLiquidacao), [
//     {
//       media: '(max-width: 768px)',
//       image: getImage(bannerLiquidacaoMobile)
//     }
//   ])
//   return <GatsbyImage image={sources} alt="Banner Delivery" className="customBannerDelivery" />
// }

const LojasDoacoes = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/lojas-doacoes/', name: 'Lojas de Apoio a Doações RS' }
    ]
  }

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState(lojas)

  const handleChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term === "") {
      setSearchResults(lojas);
    } else {
      filterResults(term);
    }
  };

  const filterResults = (term) => {
    const filteredResults = lojas.filter((item) => {
      const normalizedTerm = term.toLowerCase();
      const normalizedName = item.BAIRRO ? item.BAIRRO.toLowerCase() : "";
      const normalizedAddress = item.ENDERECO ? item.ENDERECO.toLowerCase() : "";
      const normalizedCity = item.CIDADE ? item.CIDADE.toLowerCase() : "";

      return (
        normalizedName.includes(normalizedTerm) ||
        normalizedAddress.includes(normalizedTerm) ||
        normalizedCity.includes(normalizedTerm)
      );
    });

    setSearchResults(filteredResults);
  };

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Lojas Doações - Dia Supermercados</title>
        <meta name="description" content="Conheça nossas Ofertas" />
        <meta name="keywords" content="Ofertas dia, dia supermercado" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <div className="position-relative" style={{ minheight: '200px' }}>
          <GatsbyImage
            image={data.bannerLojas.childImageSharp.gatsbyImageData}
            className="position-absolute w-100 h-100"
            alt="banner de lojas" />
          <div className="container pt-lg-0 pt-5  ">
            <div className="row d-flex justify-content-center align-items-lg-center bannerLojasPesquisa">
              <div className="col-lg-5 col-12 text-lg-left text-center">
                <h2 className="text-blue text-lojas">Lojas de Apoio a Doações RS</h2>
              </div>
              <div className="col-lg-7 col-12">
                {/* <form onSubmit={(e) => handleSearch(e)} className="d-flex ">
                  <input type="text" className="inputPesquisa w-100 rounded-left bg-white text-dark font-size-1" placeholder="Digite seu Endereço/CEP" onChange={(e) => setSearchResult(e.target.value)} value={searchResult} style={{ outline: 'none' }}/>
                  <button className="bg-white rounded-right border-0 d-lg-block d-none" style={{ outline: 'none' }}>
                    <img src={iconSearch} className="pr-3" alt="icone de busca" />
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      <div className='container py-5' id='tabela-liquidacoes'>
        <div>
          <input
            type="text"
            placeholder="Pesquisar loja por nome, endereço ou cidade..."
            className='input-liquidacoes mb-5'
            value={searchTerm}
            onChange={handleChange}
          />
          <div className='row'>
            {searchResults.map((item, index) => (
              <div key={index} className="col-lg-6 col-12 mb-5">
                <div className="card card-lojas border-0">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-red font-size-15 mt-0 mb-3">{item.BAIRRO}</h2>
                      <p className="font-size-1" style={{ lineHeight: '24px' }}>
                        {item.ENDERECO}<br />
                        {item.CIDADE} - {item.ESTADO} <br />
                      </p>
                      <p className="mt-2 font-size-09 text-gray-1">
                        Segunda a Sábado: {item.HORARIOSEGUNDASABADOINICIO} às {item.HORARIOSEGUNDASABADOFIM}<br />
                        Domingo e feriados: {item.HORARIODOMINGOFERIADOINICIO} às {item.HORARIODOMINGOFERIADOFIM}<br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default LojasDoacoes

export const query = graphql`{
  bannerLojas: file(relativePath: {eq: "lojas/bannerLojas.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
